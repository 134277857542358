<template>
    <div class="h-100 flex-display flex-column flex-center">
        <div>
            <img src="/img/logo_img.svg" alt="myonline therapy"/>
        </div>
        <h1 class="form-heading mt-5">Sign In</h1>
        <p class="app-error mt-2">
            {{error}}
        </p>
        <div class="w-343 mt-3" id="form">
            <input type="password" style="display: none;" name="fakePassword"/>
            <div class="form-group">
                <label class="form-label">Email</label>
                <input class="input-text" v-model="email" placeholder="e.g., jondoe@example.com">
            </div>
            <div class="form-group">
                <label class="form-label">Password</label>
                <input class="input-text" v-model="password" type="password" placeholder="Enter Password"/>
            </div>
            <div class="text-center mb-3">
                <router-link class="forgot-password-link" to="/forgot-password">Forgot Password?</router-link>
            </div>

            <button class="btn" :disabled="disable" @click="login">Sign In</button>
        </div>
      <confirmation-code v-if="addConfirmationCodeDialog"
                         :visible="addConfirmationCodeDialog"
                         v-on:hide="addConfirmationCodeDialog = false"
                         :user = "user"
                         :action-type="actionType"
      >
      </confirmation-code>
    </div>
</template>

<script>
import userRoles from '../helpers/app/userRoleHelper'
import ConfirmationCode from "@/views/confirmationCode";
    export default {
        name: "Login",
      components: {
          ConfirmationCode
      },
      data(){
            return{
                email: "",
                password: "",
                disable: true,
                error: "",
                addConfirmationCodeDialog: false,
                user: {},
                actionType: "login",

            }
        },
        methods:{

          login() {
            let self = this;
            let loader = this.$loading.show();
            this.$http.post(this.url + "login", {
              email: this.email,
              password: this.password
            }).then(response => {
              loader.hide();
              this.user = response.data.user;
              localStorage.setItem("user", JSON.stringify(response.data.user));
              if (this.user.phone === "" || this.user.country_code === null) {
                this.$router.push("add-phone-number");
              }
              else if(response.data.send_code === true){
                this.addConfirmationCodeDialog = true;
              }
              else{
                localStorage.setItem("token",response.data.access_token);
                localStorage.setItem("password_expire_date",response.data.password_expire_date);
                localStorage.setItem("sb_access_token",response.data.sb_access_token);
                localStorage.setItem("user",JSON.stringify(response.data.user));
                this.setHeaderToken(response.data.access_token);
                this.$router.push('dashboard')
              }
            }).catch(function (error) {
              loader.hide();
              self.error = error.response.data.message;
            });
          },
          enableButton(){
            this.disable = !(this.email !== "" && this.password !=="")
          }
        },
        watch:{
             email: function () {
                 this.enableButton();
             },
             password: function () {
                 this.enableButton();
             }
        }
    }
</script>
